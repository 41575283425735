import { createSlice } from '@reduxjs/toolkit';
import {
  PAGE_SIZE_DEFAULT,
  SORT_ASCENDING,
  SORT_DESCENDING,
} from '../../utilities/constants';

import isEmpty from '../../utilities/isEmpty';

const initialValue = {
  //forces API to reload
  filters: {},
  page: 1,
  pageSize: PAGE_SIZE_DEFAULT,
  search: '',

  sortBy: null,
  sortDirection: null,
  ids: {},
  selectValues: {},
};

export const dynamicTableSlice = createSlice({
  name: 'dynamicTable',
  initialState: {
    value: {},
    selected: {}
  },
  reducers: {
    setValue: (state, action) => {
      state.value = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    cleanUpState: (state, action) => {
      state.value = {}
      state.selected = {}
    },
  },
});

export const {
  setValue, setSelected, cleanUpState
} = dynamicTableSlice.actions;

export default dynamicTableSlice.reducer;
