import { isRejectedWithValue } from '@reduxjs/toolkit';
import { setError } from 'features/home/homeSlice';
import isEmpty from 'utilities/isEmpty';
import parseError from 'utilities/parseError';

/**
 * Log a warning and show a toast!
 */
export const errorHandler = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    let error = null;

    if (!isEmpty(action.payload?.data)) {
      error = parseError(action.payload.data);
    } else if (!isEmpty(action.payload)) {
      error = parseError(action.payload);
    }

    if (
      !isEmpty(error) &&
      (isEmpty(error.manualDisplay) || !error.manualDisplay)
    ) {
      api.dispatch(setError(error));
    }
  }

  return next(action);
};
