"use client"
import React, { useEffect, useState } from 'react';
import isEmpty from '../utilities/isEmpty';
import { loadInitialData } from '../features/home/homeActions';
import { useSelector, useDispatch } from 'react-redux';
import { clearError, setLoading, setTheme } from '../features/home/homeSlice';
import {
  lightModeTheme,
  darkModeTheme,
  ERROR_ARRAY,
} from '../utilities/constants';
import { Spinner } from 'components/controls/Spinner';
import { LoaderMain } from 'components/controls/LoaderMain';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from '@material-tailwind/react';
import { IntlProvider } from 'react-intl';

export function GlobalLoader(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.home.loading);
  const error = useSelector((state) => state.home.error);

  const [isCatastrophicError, setIsCatastrophicError] = useState(false);
  const [dialogOptions, setDialogOptions] = useState({
    showDialog: false,
    dialogMessage: null,
  });

  const getInitialThemeName = () => {
    return (
      localStorage.getItem('theme') ??
      (window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
        ? darkModeTheme
        : lightModeTheme)
    );
  };

  useEffect(() => {
    dispatch(setLoading([]));
    let theme = getInitialThemeName();
    dispatch(setTheme(theme));

    //This loads user data
    dispatch(loadInitialData([]));

    return function () { };
  }, [dispatch]);

  //this deals with Catastrophic error loader or 400 with message
  useEffect(() => {
    if (!dialogOptions.showDialog && !isCatastrophicError) {
      if (!isEmpty(error) && !isEmpty(error.code)) {
        let isCatastrophicError = ERROR_ARRAY.includes(error.code) || /^5\d{2}$/.test(error.code);
        if (isCatastrophicError) {
          // isCatastrophicError
          setIsCatastrophicError(true);
        } else {
          // show dialog and message
          setDialogOptions({
            showDialog: true,
            dialogMessage: error.message,
          });
        }
      }
    } else if (isEmpty(error)) {
      if (isCatastrophicError) {
        setIsCatastrophicError(false);
      }

      if (dialogOptions.showDialog) {
        setDialogOptions({
          showDialog: false,
          dialogMessage: null,
        });
      }
    }

    return function () { };
  }, [
    error,
    setIsCatastrophicError,
    isCatastrophicError,
    setDialogOptions,
    dialogOptions,
  ]);

  const handleOpen = () => dispatch(clearError());

  if (isCatastrophicError) {
    return <div>Catastrophic error</div>;
  }

  return (
    <>
      <IntlProvider locale={Intl.DateTimeFormat().resolvedOptions().locale ?? "en-ZA"}>

        <Dialog open={dialogOptions.showDialog} handler={handleOpen} size="xs" id="dialog_alert" >
          <DialogHeader>
            <Typography variant="h5" className="text-center" color="blue-gray">
              An error occurred
            </Typography>
          </DialogHeader>
          <DialogBody>
            <Typography className="font-normal">
              {!isEmpty(dialogOptions.dialogMessage) &&
                dialogOptions.dialogMessage}
            </Typography>
          </DialogBody>
          <DialogFooter className="space-x-2">
            <Button
              size="md"
              className="btn btn-success bg-success btn-md mt-2"
              onClick={handleOpen}>
              OK
            </Button>
          </DialogFooter>
        </Dialog>
        {props.children}
      </IntlProvider>
    </>
  );
}
