import React from 'react';
import { Spinner } from './Spinner';

export function LoaderMain() {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="block">
        <div>
          <Spinner />
        </div>
        <div className="mt-3">Loading...</div>
      </div>
    </div>
  );
}
