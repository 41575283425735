import { createSlice } from '@reduxjs/toolkit';
// import {
//     userLogin,
//     userRegistration,
//     sendOtp,
//     verifyOtp,
//     impersonate,
// } from './authActions';
import isEmpty from '../../utilities/isEmpty';
import { loadInitialData } from '../home/homeActions';
import axios from 'axios';

export const applicationSlice = createSlice({
    name: 'applications',
    initialState: {
        dialogOpen: false,
    },
    reducers: {

    },
    extraReducers: (builder) => {

    },
});

// export const { toggleDialogOpen, setFormIndex, logOut } = applicationSlice.actions;

export default applicationSlice.reducer;
